import { action, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { NavigateFunction } from 'react-router-dom';
import ProductModel from '../../models/Product';
import { addToCart, productView } from '../../services/Klaviyo';
import { track } from '../../services/Pixel';
import AuthenticationStore from '../../stores/Authentication';
import Banners from '../../stores/Banners';
import Cart, { ActionStatus } from '../../stores/Cart';
import Products from '../../stores/Products';
import Alert, { Type } from '../Alert';
import FavouriteIcon from '../Favourite/FavouriteIcon';
import Price from '../Price/Price';
import DiscountPercentage from '../Price/DiscountPercentage';
import Translate from '../Translate/Translate';
import Modal from './../Modal';
import Availability from './Availability';
import Images from './Images';
import Latest from './Latest';
import Related from './Related';
import Related2 from './Related2';
import SizeChart from './SizeChart';
import './styles/index.scss';
import TechnologyItem from './TechnologyItem';
import ErrorBanner from '../../components/404';
import ProductExtra from './ProductExtra';
import { scrollToTop } from '../../services/Util';
import InBankModal from './InBankModal';
import { Tooltip } from 'react-tooltip';
import ClickAndTryModal from './ClickAndTryModal';
import Uus from '../../images/uus.svg';
import Stroke from '../../images/underline-stroke.svg';
import parse from 'html-react-parser';
import Header from './HeaderBreadcrumbs';

interface Props {
  slug: string;
  products?: Products;
  cart?: Cart;
  banners?: Banners;
  authentication?: AuthenticationStore;
  navigate: NavigateFunction;
  product: ProductModel;
  productAddedToCart: (productId: number) => void;
}

@inject('products', 'cart', 'authentication', 'banners')
@observer
export default class Product extends React.Component<Props> {
  currentProductSlug = '';

  @observable product: ProductModel = null;
  @observable loading = true;
  @observable quantities = [];
  @observable currentCategory = '';
  @observable currentVariation = '';
  @observable buyConfirmation = '';
  @observable isVariationWarningVisible = false;
  @observable latestPosts = [];
  @observable error = false;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.addCurrentVariationToCart = this.addCurrentVariationToCart.bind(this);
    this.recc = this.recc.bind(this);
    this.goBackClick = this.goBackClick.bind(this);
  }

  componentDidMount() {
    this.updateProduct();
  }

  componentDidUpdate() {
    this.updateProduct();
  }

  async updateProduct() {
    const { slug, products, product } = this.props;

    if (slug === this.currentProductSlug) {
      return;
    }

    this.currentProductSlug = slug;

    scrollToTop();

    this.setLoading(true);

    // Load product data
    try {
      this.setProduct(product);
      if (product.categories.length) {
        this.setCurrentCategory(product.categories[0]);
      }
      // const latestPostsList = await posts.loadLatestPostsByAmount(2);
      // this.setLatestPosts(latestPostsList);
      this.setError(false);
    } catch (err) {
      // Error while loading product info. Probably product not available anymore.
      this.setProduct(null);
      this.setError(true);
      this.setLoading(false);
    }

    // Load quantities and shops only if product is actually available
    if (this.isProductAvailable) {
      const quantities = await products.loadQuantities(slug);
      this.setQuantities(quantities);

      this.setLoading(false);

      const finalPrice = this.product.finalPrice(true);
      const defaultPrice = this.product.defaultPrice;
      const discountAmount = defaultPrice - finalPrice;

      // @ts-ignore
      if (dataLayer) {
        // @ts-ignore
        dataLayer.push({ ecommerce: null });
        // @ts-ignore
        dataLayer.push({
          event: 'view_item',
          ecommerce: {
            currency: 'EUR',
            value: finalPrice,
            items: [
              {
                item_id: this.product.id,
                item_name: this.product.brandTitle + ' ' + this.product.title,
                item_brand: this.product.brandTitle,
                item_category: this.product.categoryTitle,
                quantity: 1,
                price: defaultPrice,
                discount: Number(discountAmount.toFixed(2)),
              },
            ],
          },
        });
      }

      track('ViewContent', {
        content_type: 'product',
        currency: 'EUR',
        value: this.product.finalPrice(),
        contents: [
          {
            id: this.product.data.id,
            quantity: 1,
            item_price: this.product.finalPrice(),
          },
        ],
      });
    }
  }

  @action setError(status: boolean) {
    this.error = status;
  }

  get isProductAvailable() {
    return !!this.product;
  }

  @action setLatestPosts(posts) {
    this.latestPosts = posts;
  }

  @action setLoading(newStatus: boolean) {
    this.loading = newStatus;
  }

  @action setProduct(product: ProductModel) {
    this.product = product;
  }

  @action setQuantities(quantities) {
    this.quantities = quantities;
  }

  @action setCurrentCategory(category) {
    this.currentCategory = category.slug;
  }

  @action setCurrentVariation(code: string) {
    this.currentVariation = code;
    this.displayVariationWarning(false);
  }

  @action buttonClicked() {
    const { cart } = this.props;

    if (cart.lastActionStatus === ActionStatus.OUT_OF_STOCK) {
      this.buyConfirmation = 'false';
    } else {
      this.buyConfirmation = 'true';
    }

    setTimeout(() => {
      this.currentVariation = '';
    }, 1000);
  }

  @action displayVariationWarning(isVisible = true) {
    this.isVariationWarningVisible = isVisible;
  }

  goBackClick() {
    const { navigate } = this.props;
    navigate(-1);
  }

  async addCurrentVariationToCart() {
    const { cart, authentication, productAddedToCart } = this.props;

    // Hide variation warning if it is visible
    this.displayVariationWarning(false);

    // Variation has not been selected. Display warning.
    if (!this.currentVariation) {
      this.displayVariationWarning(true);
      return;
    }

    productAddedToCart(this.product.id);

    await cart.addItem(this.currentVariation);

    addToCart(cart, authentication.loggedIn);

    this.buttonClicked();

    const finalPrice = this.product.finalPrice(true);
    const defaultPrice = this.product.defaultPrice;
    const discountAmount = defaultPrice - finalPrice;

    const selectedVariation = this.product.sizeVariations.find(
      (variation) => variation.code === this.currentVariation,
    );

    // @ts-ignore
    if (dataLayer) {
      // @ts-ignore
      dataLayer.push({ ecommerce: null });

      // @ts-ignore
      dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'EUR',
          value: finalPrice,
          items: [
            {
              item_id: this.product.id,
              item_name: this.product.brandTitle + ' ' + this.product.title,
              item_brand: this.product.brandTitle,
              quantity: 1,
              price: defaultPrice,
              discount: Number(discountAmount.toFixed(2)),
              item_variant: selectedVariation
                ? selectedVariation.value
                : undefined,
            },
          ],
        },
      });
    }

    track('AddToCart', {
      content_type: 'product',
      currency: 'EUR',
      value: this.product.finalPrice(),
      contents: [
        {
          id: this.product.data.id,
          quantity: 1,
          item_price: this.product.finalPrice(),
        },
      ],
    });
  }

  recc(products) {
    const { authentication } = this.props;
    productView(this.product, authentication.loggedIn, products);
  }

  render() {
    if (this.loading) {
      return <div className="product loading" />;
    }

    if (this.error) {
      return <ErrorBanner titleKeyword="product" />;
    }

    if (!this.isProductAvailable) {
      return null;
    }

    // let breadcrumbs = null;
    // if (this.currentCategory) {
    //   breadcrumbs = <Breadcrumbs slug={this.currentCategory} />;
    // }

    let sizes = null;
    let hasBicycleHeight = false;
    let productCode;
    if (this.product.sizeVariations.length) {
      sizes = this.product.sizeVariations.map((size) => {
        const quantity = this.quantities.find(
          (q) => q.variation.code === size.code,
        );
        const available = quantity && quantity.quantity;

        if (!available) {
          return null;
        }

        let className = 'size-variation';
        if (!available) {
          className += ' disabled';
        }

        if (this.currentVariation === size.code) {
          className += ' active';
          productCode = (
            <div
              className="relative inline-block cursor-pointer ml-auto"
              data-tooltip-id="variation"
              data-tooltip-content={this.currentVariation}
            >
              <p className="text-xs text-rgrey">
                <Translate component="product" keyword="code-tooltip" />
              </p>
              <Tooltip id="variation" clickable />
            </div>
          );
        }

        const onClick = this.setCurrentVariation.bind(this, size.code);

        const heightMeta = size.meta.find(
          (metaItem) => metaItem.meta_key === 'bicycle_height',
        );

        if (heightMeta) {
          hasBicycleHeight = true;
        }

        return (
          <div className={className} key={size.code} onClick={onClick}>
            {heightMeta
              ? size.value + ' (' + heightMeta.meta_value + ')'
              : size.value}
          </div>
        );
      });
    }

    let description = null;
    if (this.product.descriptionText) {
      description = (
        <div className="product__description">
          {parse(this.product.descriptionText)}
        </div>
      );
    }

    let introduction = null;
    if (this.product.introduction) {
      introduction = (
        <div className="product__introduction">
          {parse(this.product.introduction)}
        </div>
      );
    }

    let specifications = null;
    if (this.product.specifications) {
      specifications = (
        <div className="product__specifications">
          {this.product.specifications.map((spec) => (
            <div key={spec.id} className="product__specifications-item">
              <p>{parse(spec.title)}</p>
              <p>{parse(spec.description)}</p>
            </div>
          ))}
        </div>
      );
    }

    let technologies = null;
    if (this.product.technologies.length) {
      technologies = (
        <div className="product__technologies">
          {this.product.technologies.map((technology) => (
            <TechnologyItem key={technology.id} data={technology} />
          ))}
        </div>
      );
    }

    let content = null;
    if (this.product.description) {
      content = (
        <div className="product__content">
          <h3 className="my-5">Toote tutvustus</h3>
          {introduction}
          {technologies}
          {description}
          {specifications}
        </div>
      );
    } else {
      content = (
        <div className="product__content font-axi-book paragraph">
          Hei! Nii vahva, et Sa oled juba siin, ehkki sellele tootele kahjuks
          veel kirjeldus puudub.
          <br />
          <br />
          Meie #liikumiseksloodud tiim tegutseb praegu just selle nimel, et iga
          toode, ka see, saaks põhjaliku tutvustuse — ikka selleks, et Sul oleks
          võimalikult lihtne oma lemmikud välja valida.
          <br />
          <br />
          Kui Sul tekib selle või mõne teise toote kohta küsimusi, siis kirjuta
          julgelt siin Rademari e-poes olevasse LiveChat aknasse,
          <a className="text-rgreen" href="mailto: epood@rademar.ee">
            {' '}
            epood@rademar.ee
          </a>{' '}
          või helista, et saaksime Sulle abiks olla!{' '}
        </div>
      );
    }

    let buyConfirmMessage;
    if (this.buyConfirmation === 'true') {
      buyConfirmMessage = (
        <Alert show={!!this.buyConfirmation} type={Type.SUCCESS}>
          <Translate component="product" keyword="add-to-cart-ok" />
        </Alert>
      );
      setTimeout(() => {
        this.buyConfirmation = '';
      }, 4000);
    }

    if (this.buyConfirmation === 'false') {
      buyConfirmMessage = (
        <Alert show={!!this.buyConfirmation} type={Type.ERROR}>
          <Translate component="product" keyword="add-to-cart-failed" />
        </Alert>
      );
      setTimeout(() => {
        this.buyConfirmation = '';
      }, 4000);
    }

    const availability = this.quantities.length ? (
      <Availability
        quantities={this.quantities}
        variations={this.product.sizeVariations}
      />
    ) : null;

    return (
      <div className="product">
        <Header
          goBackClick={this.goBackClick}
          currentCategory={this.currentCategory}
        />
        <Helmet>
          <title>
            {this.product.brandTitle} {this.product.title} |{' '}
            {this.product.categoryTitle} | Rademar e-pood
          </title>
          <meta name="description" content={this.product.meta_description} />
        </Helmet>
        <div
          className={`product__item ${this.product.isBike() ? 'isBike' : ''}`}
        >
          <div className="product__touch">
            <div className="product__touch__header">
              <h1 className="product__touch__title">
                {this.product.brandTitle} {this.product.title}
              </h1>
              <span className="product__touch__category">
                {this.product.productTitle}
              </span>
            </div>
            <div className="product__touch__discounts">
              <Price
                prices={this.product.prices(true)}
                priceLabel={false}
                productNew={this.product.isNew}
                discountPriceClassname="text-xl mr-2"
                defaultPriceClassName="text-sm"
                discountLabelClassname="hidden"
              />
              <div className="flex">
                <DiscountPercentage
                  prices={this.product.prices(true)}
                  productNew={this.product.isNew}
                  componentClassname="relative"
                  containerClassname="items-start mt-1"
                />
                <Price
                  prices={this.product.prices(true)}
                  priceLabel={true}
                  productNew={this.product.isNew}
                  discountPriceClassname="hidden"
                  defaultPriceClassName="hidden"
                  discountLabelClassname="text-xs"
                />
              </div>
            </div>
          </div>
          <div className="product__image">
            <Images
              images={this.product.images}
              productSlug={this.product.slug}
              productNew={this.product.isNew}
              prices={this.product.prices(true)}
            />
            <div className="product__image__favourite">
              <FavouriteIcon
                type="button"
                productSlug={this.product.slug}
                componentClassname="hidden"
              />
            </div>
          </div>
          <div className="product__info">
            <div className="product__info__inner">
              <div className="product__info__discounts">
                <DiscountPercentage
                  prices={this.product.prices(true)}
                  productNew={this.product.isNew}
                  componentClassname="relative"
                  containerClassname="items-start p-0 mb-2"
                />
              </div>
              <h1 className="product__info__title">
                {this.product.brandTitle} {this.product.title}
              </h1>
              <span className="product__info__category">
                {this.product.productTitle}
              </span>
              <div className="product__info__price">
                <Price
                  prices={this.product.prices(true)}
                  priceLabel={true}
                  productNew={this.product.isNew}
                  discountPriceClassname="text-3xl mr-3"
                  defaultPriceClassName="text-xl"
                  discountLabelClassname="text-xs"
                />
              </div>
              <Related productSlug={this.product.slug} />
              <div className="product__info__sizes">
                <div className="product__info__sizes__header">
                  <h5>
                    {hasBicycleHeight ? (
                      <Translate
                        component="product"
                        keyword="choose-bike-size"
                      />
                    ) : (
                      <Translate component="product" keyword="choose-size" />
                    )}
                  </h5>
                  <SizeChart productSlug={this.product.slug} />
                  {productCode}
                </div>
                {sizes}
              </div>
              <div className="product__info__buttons">
                <Alert
                  show={this.isVariationWarningVisible}
                  type={Type.WARNING}
                >
                  <Translate component="product" keyword="choose-size" />
                </Alert>

                {buyConfirmMessage}
                <div className="product__info__buttons__header">
                  <FavouriteIcon
                    type="button"
                    productSlug={this.product.slug}
                    componentClassname="relative border rounded mr-2.5 px-2 py-1.5 border-solid border-rgrey-light"
                  />

                  <button
                    className={`add-to-cart ${
                      this.currentVariation !== '' ? ' active' : ''
                    }`}
                    onClick={this.addCurrentVariationToCart}

                    // disabled={!this.currentVariation}
                  >
                    <Translate component="product" keyword="add-to-cart" />
                  </button>
                </div>

                <div className="product__info__buttons__footer">
                  <Modal
                    buttonClass="product__info__buttons__footer__availability"
                    button={
                      <Translate component="product" keyword="availability" />
                    }
                    content={availability}
                    contentTitle={
                      <Translate component="product" keyword="availability" />
                    }
                    modalClass="center overflow-auto overflow-x-auto min-h-fit"
                  />
                  <div className="flex items-center gap-2 mt-5">
                    <div className="text-rgreen">
                      <div className="inline-block relative">
                        <img
                          src={Uus}
                          alt="uus"
                          className="w-8 h-8 inline-block"
                        />
                        <img
                          src={Stroke}
                          alt="underline brush stroke"
                          className="absolute inset-x-0 w-full -mt-2"
                        />
                      </div>
                    </div>
                    <div className="font-axi-bold text-wrap mt-1">
                      <Translate
                        component="checkout"
                        keyword="modena-payment"
                      />
                    </div>
                    <Modal
                      buttonClass="rounded-full text-rgrey border border-rgrey shrink-0  w-5 h-5 text-center py-0.5 justify-center items-center hover:text-white hover:bg-black hover:border-black hover:cursor-pointer"
                      button="i"
                      content={<ClickAndTryModal />}
                      contentTitle=""
                      modalClass="center overflow-auto min-h-96 md:min-h-fit"
                    />
                  </div>
                  <div className="text-xs tracking-tight mt-2 pb-5 border-b">
                    Vali e-poest välja kuni viis toodet ja telli need koju
                    proovimiseks!
                  </div>
                  {this.product.finalPrice() <= 2500 && (
                    <div className="mt-5 pb-5 border-b">
                      <div className="flex gap-4 mt-5 justify-between items-center">
                        <div className="flex items-center gap-2">
                          <div className="font-axi-bold text-wrap">
                            Maksa 3 või 6 võrdses osas
                          </div>
                          <Modal
                            buttonClass="rounded-full text-rgrey border border-rgrey shrink-0  w-5 h-5 text-center py-0.5 justify-center items-center hover:text-white hover:bg-black hover:border-black hover:cursor-pointer"
                            button="i"
                            content={
                              <InBankModal price={this.product.finalPrice()} />
                            }
                            contentTitle={
                              <img
                                src="https://rademar-1b47c.kxcdn.com/icons/inbank.svg"
                                alt="inbank"
                                className="h-10 w-auto m-5"
                              />
                            }
                            modalClass="center overflow-auto min-h-96 md:min-h-fit"
                          />
                        </div>
                        <div className="flex gap-4 mr-2">
                          <div className="text-rpink text-nowrap whitespace-nowrap">
                            3<span className="text-xs"> &#10005; </span>
                            {(this.product.finalPrice() / 3).toFixed(2)} €
                          </div>
                          <div className="text-rpink text-nowrap whitespace-nowrap">
                            6<span className="text-xs"> &#10005; </span>
                            {(this.product.finalPrice() / 6).toFixed(2)} €
                          </div>
                        </div>
                      </div>
                      {this.product.finalPrice() < 50 && (
                        <div className="text-xs tracking-tight mt-2">
                          Maksete jaotamiseks peab ostukorv olema vahemikus
                          50-2500 eur.
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <ProductExtra isBike={this.product.isBike()} />
            </div>
          </div>
          {content}
        </div>
        <Related2 productId={this.product.id} />
        <Latest
          productSlug={this.product.slug}
          title={<Translate component="product" keyword="history" />}
        />
      </div>
    );
  }
}
